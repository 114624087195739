<template>
  <v-container v-if="flag" class="mt-8 text-center" style="max-width: max-content">
    <v-row class="mb-10">
      <v-col cols="12" xs="12">
        <div class="text-h4 font-weight-bold my-font">Dashboard de operación</div>
      </v-col>
      <v-col cols="12" xs="12">
        <v-btn class="text-h6 mt-2 my-font" text @click="openFilters('range')">
          {{ rangeDates }}
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" xs="12" class="d-flex flex-wrap justify-center" style="gap: 20px">
        <v-autocomplete
          v-model="form.categories"
          :items="catalogues.categories"
          @change="refresh()"
          item-text="name"
          item-value="id_category"
          label="Categorías"
          :disabled="loading"
          chips
          small-chips
          dense
          hide-details
          multiple
          height="40px"
          rounded
          outlined
          style="width: 270px; max-width: 300px"
        >
          <template v-slot:prepend-item>
            <v-list-item @change="refresh()" ripple @mousedown.prevent @click="toggleCategories()">
              <v-list-item-action>
                <v-icon :color="form.categories.length > 0 ? 'indigo darken-4' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Seleccionar todo </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip style="height: 20px" v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"> (+{{ form.categories.length - 1 }} mas) </span>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="form.subcategories"
          :items="catalogues.subcategories"
          @change="refresh()"
          item-text="name"
          item-value="id_subcategory"
          label="Subcategorías"
          chips
          small-chips
          :disabled="loading"
          hide-details
          multiple
          rounded
          height="40px"
          dense
          outlined
          style="width: 270px; max-width: 300px"
        >
          <template v-slot:prepend-item>
            <v-list-item @change="refresh()" ripple @mousedown.prevent @click="toggleSubCategories()">
              <v-list-item-action>
                <v-icon :color="form.subcategories.length > 0 ? 'indigo darken-4' : ''">
                  {{ iconSubcategory }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Seleccionar todo </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip style="height: 20px" v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"> (+{{ form.subcategories.length - 1 }} mas) </span>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="form.labels"
          :items="catalogues.labels"
          @change="refresh()"
          item-text="name"
          item-value="name"
          label="Etiquetas"
          :disabled="loading"
          chips
          hide-details
          multiple
          height="40px"
          rounded
          dense
          outlined
          style="width: 270px; max-width: 300px"
        >
          <template v-slot:prepend-item>
            <v-list-item @change="refresh()" ripple @mousedown.prevent @click="toggleLabels()">
              <v-list-item-action>
                <v-icon :color="form.labels.length > 0 ? 'indigo darken-4' : ''">
                  {{ iconLabels }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Seleccionar todo </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip style="height: 20px" v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"> (+{{ form.labels.length - 1 }} mas) </span>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="form.user_create"
          :items="catalogues.user_create"
          @change="refresh()"
          item-text="username"
          item-value="username"
          label="Ususario creador de campaña"
          :disabled="loading"
          hide-details
          multiple
          height="40px"
          rounded
          dense
          outlined
          style="width: 270px; max-width: 300px"
        >
          <template v-slot:prepend-item>
            <v-list-item @change="refresh()" ripple @mousedown.prevent @click="toggleUsers()">
              <v-list-item-action>
                <v-icon :color="form.user_create.length > 0 ? 'indigo darken-4' : ''">
                  {{ iconUsers }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Seleccionar todo </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip style="height: 20px" v-if="index === 0">
              <span>{{ item.username.split("@")[0] }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"> (+{{ form.user_create.length - 1 }} mas) </span>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="form.providers"
          :items="catalogues.providers"
          @change="refresh()"
          item-text="name"
          item-value="name"
          label="Provedor"
          :disabled="loading"
          hide-details
          chips
          height="40px"
          multiple
          rounded
          dense
          outlined
          style="width: 270px; max-width: 300px"
        >
          <template v-slot:prepend-item>
            <v-list-item @change="refresh()" ripple @mousedown.prevent @click="toggleProviders()">
              <v-list-item-action>
                <v-icon :color="form.providers.length > 0 ? 'indigo darken-4' : ''">
                  {{ iconProvider }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Seleccionar todo </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip style="height: 20px" v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption"> (+{{ form.providers.length - 1 }} mas) </span>
          </template>
        </v-autocomplete>
        <v-btn color="primary" rounded class="text-normal" dark :loading="loading" @click="downloadMessages()">
          Descargar XLSX
        </v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-dialog v-model="dialogs.range_dates" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="primary" elevation="2">
          <v-toolbar-title>Rango de fechas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="refresh()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formFilters" lazy-va2lidation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-dialog
                    ref="startDateDialog"
                    v-model="dialogs.start_date"
                    :return-value.sync="form.start_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.start_date"
                        label="Fecha inicio"
                        :rules="rules.start_date"
                        :disabled="loading"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.start_date" locale="es" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogs.start_date = false"> Cancelar </v-btn>
                      <v-btn text color="primary" @click="$refs.startDateDialog.save(form.start_date)"> Aceptar </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-dialog
                    ref="endDateDialog"
                    v-model="dialogs.end_date"
                    :return-value.sync="form.end_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.end_date"
                        label="Fecha fin"
                        :rules="rules.end_date"
                        :disabled="loading"
                        dense
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.end_date" locale="es" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dialogs.end_date = false"> Cancelar </v-btn>
                      <v-btn text color="primary" @click="$refs.endDateDialog.save(form.end_date)"> Aceptar </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-10" style="background-color: #f5f5f5">
      <v-row class="pt-2">
        <v-col cols="12" md="3" xs="12">
          <div class="text-h2">{{ kpi.delivered_messages }}</div>
          <div class="font-weight-bold">Mensajes entregados</div>
        </v-col>
        <v-col cols="12" md="3" xs="12">
          <div class="text-h2">{{ kpi.errored_messages }}</div>
          <div class="font-weight-bold">Mensajes con error</div>
        </v-col>
        <v-col cols="12" md="3" xs="12">
          <div class="text-h2">{{ kpi.different_destinations }}</div>
          <div class="font-weight-bold">Destinatarios distintos</div>
        </v-col>
        <v-col cols="12" md="3" xs="12">
          <div class="text-h2">{{ kpi.total_cost }}</div>
          <div class="font-weight-bold">Costo total (USD)</div>
        </v-col>
      </v-row>
      <v-row class="pt-10">
        <v-col cols="12" md="6" xs="12">
          <div class="font-weight-bold">Mensajes entregados por categoría</div>
          <Doughnut :height="350" :chartData="charts.categories.data" :options="charts.categories.options" />
        </v-col>
        <v-col cols="12" md="6" xs="12">
          <div class="font-weight-bold">Mensajes entregados por subcategoría</div>
          <Doughnut :height="350" :chartData="charts.subcategories.data" :options="charts.subcategories.options" />
        </v-col>
      </v-row>
      <v-row class="pt-10">
        <v-col cols="12" md="6" xs="12">
          <div class="font-weight-bold">Historial de mensajes/campaña</div>
          <Bar :height="350" :chartData="this.charts.bar.dataAllMessages" :options="charts.bar.options" />
        </v-col>
        <v-col cols="12" md="6" xs="12">
          <div class="font-weight-bold">Destinatarios distintos/campaña</div>
          <Bar :height="350" :chartData="this.charts.bar.dataPerNumber" :options="charts.bar.options" />
        </v-col>
      </v-row>
      <v-row class="py-10">
        <v-col cols="12" md="4" xs="12">
          <div class="font-weight-bold" style="padding-bottom: 25px">Mensajes por etiqueta</div>
          <v-data-table
            :headers="headersLabels"
            :items="table.labels"
            :loading="loading"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
            :items-per-page="10"
            item-key="id"
            class="elevation-1"
            dense
          >
            <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
            <template v-slot:[`footer.page-text`]="props">
              Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:no-data> No hay información disponible </template>
            <template v-slot:no-results> No se obtuvieron resultados </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="4" xs="12">
          <div class="font-weight-bold" style="padding-bottom: 25px">Últimas 10 campañas ejecutadas</div>
          <v-data-table
            :headers="headersCampaigns"
            :items="table.campaigns"
            :loading="loading"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
            :items-per-page="10"
            item-key="id"
            class="elevation-1"
            dense
          >
            <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
            <template v-slot:[`footer.page-text`]="props">
              Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:no-data> No hay información disponible </template>
            <template v-slot:no-results> No se obtuvieron resultados </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="4" xs="12">
          <div class="font-weight-bold" style="padding-bottom: 25px">Últimos 50 mensajes entregados</div>
          <v-data-table
            :headers="headersMessages"
            :items="table.messages"
            :loading="loading"
            :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [5, 50, -1] }"
            :items-per-page="10"
            item-key="id"
            class="elevation-1"
            dense
          >
            <v-progress-linear slot="progress" color="primary" indeterminate></v-progress-linear>
            <template v-slot:[`footer.page-text`]="props">
              Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:no-data> No hay información disponible </template>
            <template v-slot:no-results> No se obtuvieron resultados </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <v-overlay absolute :value="loading" opacity="0.8">
      <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
    </v-overlay>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
  <v-container v-else fluid fill-height class="text-center">
    <v-row>
      <v-col cols="12">
        <v-img :src="logo" max-height="250" contain />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import services from "@/utils/services";
import Doughnut from "../components/chart/Doughnut.js";
import Bar from "../components/chart/Bar.js";
import writeXlsxFile from "write-excel-file";
import moment from "moment-timezone";
import _ from "lodash";
import { getPastelColor } from "pastel-color";

export default {
  name: "home",
  components: { Doughnut, Bar },
  data: () => ({
    logo: require("@/assets/logo_home.png"),
    loading: false,
    success: false,
    msgSuccess: "",
    error: false,
    msgError: "",
    flag: false,
    rangeDates: "",
    catalogues: {
      months: [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ],
      categories: [],
      labels: [],
      user_create: [],
      providers: [],
      subcategories: [],
    },
    form: {
      start_date: null,
      end_date: null,
      categories: [],
      subcategories: [],
      labels: [],
      user_create: [],
      providers: [],
    },
    state: [],
    allSelected: false,
    kpi: {
      delivered_messages: 0,
      errored_messages: 0,
      different_destinations: 0,
      total_cost: 0,
    },
    charts: {
      categories: {
        data: null,
        options: null,
      },
      subcategories: {
        data: null,
        options: null,
      },
      bar: {
        data: null,
        dataAllMessages: null,
        dataPerNumber: null,
        options: null,
      },
    },
    table: {
      labels: [],
      campaigns: [],
      messages: [],
    },
    rules: {
      start_date: [(v) => !!v || "El campo es requerido"],
      end_date: [(v) => !!v || "El campo es requerido"],
    },
    headersLabels: [
      { text: "Etiqueta", align: "left", sortable: false, value: "label" },
      { text: "Mensajes", align: "left", sortable: false, value: "messages" },
    ],
    headersCampaigns: [
      { text: "Fecha", align: "left", sortable: false, value: "end_date" },
      { text: "Campaña", align: "left", sortable: false, value: "name" },
      { text: "Mensajes", align: "left", sortable: false, value: "messages" },
    ],
    headersMessages: [
      { text: "Fecha", align: "left", sortable: false, value: "date" },
      { text: "Destinatario", align: "left", sortable: false, value: "to" },
      { text: "Mensaje", align: "left", sortable: false, value: "message" },
    ],
    dialogs: {
      range_dates: false,
      start_date: false,
      end_date: false,
      categories: false,
      labels: false,
      user_create: false,
      providers: false,
      subcategories: false,
    },
  }),
  mounted() {
    this.initDashboard();
  },
  computed: {
    allCategories() {
      return this.form.categories.length === this.catalogues.categories.length;
    },
    someCategories() {
      return this.form.categories.length > 0 && !this.allCategories;
    },
    icon() {
      if (this.allCategories) return "mdi-close-box";
      if (this.someCategories) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    allSubCategories() {
      return this.form.subcategories.length === this.catalogues.subcategories.length;
    },
    someSubCategories() {
      return this.form.subcategories.length > 0 && !this.allSubCategories;
    },
    iconSubcategory() {
      if (this.allSubCategories) return "mdi-close-box";
      if (this.someSubCategories) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    allLabels() {
      return this.form.labels.length === this.catalogues.labels.length;
    },
    someLabels() {
      return this.form.labels.length > 0 && !this.allLabels;
    },
    iconLabels() {
      if (this.allLabels) return "mdi-close-box";
      if (this.someLabels) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    allUsers() {
      return this.form.user_create.length === this.catalogues.user_create.length;
    },
    someUsers() {
      return this.form.user_create.length > 0 && !this.allUsers;
    },
    iconUsers() {
      if (this.allUsers) return "mdi-close-box";
      if (this.someUsers) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    allProviders() {
      return this.form.providers.length === this.catalogues.providers.length;
    },
    someProviders() {
      return this.form.providers.length > 0 && !this.allProviders;
    },
    iconProvider() {
      if (this.allProviders) return "mdi-close-box";
      if (this.someProviders) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    initDashboard() {
      let roles = this.$session.get("roles");
      if (roles.indexOf("admin") >= 0 || roles.indexOf("gestor y reportes") >= 0) {
        this.flag = true;
        this.getCatalogues();
        this.setFilters();
        this.refresh();
      }
    },
    async getCatalogues() {
      const category = await this.axios.get(services.routes.category);
      if (category.data.data) {
        this.catalogues.categories = category.data.data;
      }
      const label = await this.axios.get(services.routes.label);
      if (label.data.data) {
        this.catalogues.labels = label.data.data;
      }
      const users = await this.axios.get(services.routes.user);
      if (users.data.data) {
        this.catalogues.user_create = users.data.data;
      }
      const providers = await this.axios.get(services.routes.provider);
      if (providers.data.data) {
        this.catalogues.providers = providers.data.data;
      }
      const subcategories = await this.axios.get(services.routes.subcategory);
      if (subcategories.data.data) {
        this.catalogues.subcategories = subcategories.data.data;
      }
    },
    setFilters() {
      let initDate = moment.tz("America/Monterrey");
      const date = initDate.format("YYYY-MM-DD");
      const startDate = moment.tz(date, "America/Monterrey").startOf("month").format("YYYY-MM-DD");
      const endDate = moment.tz(date, "America/Monterrey").endOf("month").format("YYYY-MM-DD");
      this.form.start_date = startDate;
      this.form.end_date = endDate;
    },
    setRangeDates() {
      const startMonth = moment.tz(this.form.start_date, "America/Monterrey").month();
      const endMonth = moment.tz(this.form.end_date, "America/Monterrey").month();
      this.rangeDates =
        `${this.form.start_date.split("-")[2]} de ${this.catalogues.months[startMonth]} de ${
          this.form.start_date.split("-")[0]
        } - ` +
        `${this.form.end_date.split("-")[2]} de ${this.catalogues.months[endMonth]} de ${
          this.form.end_date.split("-")[0]
        }`;
    },
    refresh() {
      this.loading = true;
      this.setRangeDates();
      let params = JSON.parse(JSON.stringify(this.form));
      this.axios.get(services.routes.dashboard + "/kpi", { params }).then((response) => {
        this.kpi = response.data.data;
        this.cancel();
        this.loading = false;
      });
      this.axios.get(services.routes.dashboard + "/graph", { params }).then((response) => {
        this.charts.categories.data = response.data.data.categories;
        this.charts.categories.options = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            labels: {
              fontFamily: "Quicksand",
            },
          },
        };
        this.charts.subcategories.data = response.data.data.subcategories;
        this.charts.subcategories.options = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            labels: {
              fontFamily: "Quicksand",
            },
          },
        };
      });
      this.axios.get(services.routes.dashboard + "/table", { params }).then((response) => {
        this.table.labels = response.data.data.labels;
        this.table.campaigns = response.data.data.campaigns;
        this.table.messages = response.data.data.messages;
      });

      this.axios.get(services.routes.dashboard + "/bar", { params }).then((response) => {
        const data = response.data.data;
        const months = data.map((m) => m.month);
        const dataBar = {
          labels: _.uniq(months),
          datasets: [],
        };

        let byMonth = {};
        for (let e of data) {
          if (!byMonth[e.month]) {
            byMonth[e.month] = [];
          }
          byMonth[e.month].push(e);
        }

        for (let [index, value] of dataBar.labels.entries()) {
          for (let f of byMonth[value]) {
            let find = dataBar.datasets.find((g) => g.label == `${f.category}-${f.subcategory}`);
            if (!find) {
              dataBar.datasets.push({
                label: `${f.category}-${f.subcategory}`,
                backgroundColor: getPastelColor(`${f.category}-${f.subcategory}`).hex,
                data: Array(dataBar.labels.length).fill(0),
                data2: Array(dataBar.labels.length).fill(0),
              });
            }

            find = dataBar.datasets.find((g) => g.label == `${f.category}-${f.subcategory}`);
            find.data[index] = parseInt(f.messages);
            find.data2[index] = parseInt(f.diff_numbers);
          }
        }

        this.charts.bar.dataAllMessages = _.cloneDeep(dataBar);

        for (let e of dataBar.datasets) {
          e.data = e.data2;
        }

        this.charts.bar.dataPerNumber = _.cloneDeep(dataBar);
      });
    },
    openFilters(type) {
      if (type === "range") {
        this.dialogs.range_dates = true;
      } else if (type === "categories") {
        this.dialogs.categories = true;
      } else if (type === "labels") {
        this.dialogs.labels = true;
      } else if (type === "user_create") {
        this.dialogs.user_create = true;
      } else if (type === "providers") {
        this.dialogs.providers = true;
      } else if (type === "subcategories") {
        this.dialogs.subcategories = true;
      }
    },
    cancel() {
      this.dialogs.range_dates = false;
      this.dialogs.categories = false;
      this.dialogs.labels = false;
      this.dialogs.user_create = false;
      this.dialogs.providers = false;
      this.dialogs.subcategories = false;
    },
    downloadMessages() {
      this.loading = true;
      let params = JSON.parse(JSON.stringify(this.form));
      this.axios.get(services.routes.dashboard + "/message", { params }).then((response) => {
        if (response.data.data.length) {
          let data = [];
          let headers = [];
          for (let key in response.data.data[0]) {
            headers.push({
              value: key,
              fontWeight: "bold",
            });
          }
          data.push(headers);
          for (let i = 0; i < response.data.data.length; i++) {
            let rows = [];
            for (let key in response.data.data[0]) {
              rows.push({
                type: String,
                value: response.data.data[i][key],
              });
            }
            data.push(rows);
          }
          writeXlsxFile(data, { fileName: `Reporte de mensajes ${this.form.start_date} ${this.form.end_date}.xlsx` });
          this.loading = false;
        } else {
          this.error = true;
          this.msgError = "No hay información disponible";
        }
      });
    },
    toggleCategories() {
      this.$nextTick(() => {
        if (this.allCategories) {
          this.allCategoriesSelected = false;
          this.form.categories = [];
        } else {
          this.allCategoriesSelected = true;
          this.form.categories = this.catalogues.categories.map((e) => e.id_category);
        }
      });
    },
    toggleSubCategories() {
      this.$nextTick(() => {
        if (this.allSubCategories) {
          this.allCategoriesSelected = false;
          this.form.subcategories = [];
        } else {
          this.allCategoriesSelected = true;
          this.form.subcategories = this.catalogues.subcategories.map((e) => e.id_subcategory);
        }
      });
    },
    toggleLabels() {
      this.$nextTick(() => {
        if (this.allLabels) {
          this.allCategoriesSelected = false;
          this.form.labels = [];
        } else {
          this.allCategoriesSelected = true;
          this.form.labels = this.catalogues.labels.map((e) => e.name);
        }
      });
    },
    toggleUsers() {
      this.$nextTick(() => {
        if (this.allUsers) {
          this.allCategoriesSelected = false;
          this.form.user_create = [];
        } else {
          this.allCategoriesSelected = true;
          this.form.user_create = this.catalogues.user_create.map((e) => e.username);
        }
      });
    },
    toggleProviders() {
      this.$nextTick(() => {
        if (this.allProviders) {
          this.allCategoriesSelected = false;
          this.form.providers = [];
        } else {
          this.allCategoriesSelected = true;
          this.form.providers = this.catalogues.providers.map((e) => e.name);
        }
      });
    },
  },
};
</script>

<style scoped>
.text-normal {
  text-transform: none !important;
}
.vl {
  border-left: 1px solid black;
  height: 100px;
}
.my-font {
  font-family: "Quicksand", sans-serif !important;
}
</style>
